import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./productDetailPage.scss";
import CouponIcon from "../icons/CouponIcon";
import { useDispatch, useSelector } from "react-redux";
import { getProductBySku } from "../reducers/products/actions";
import { selectProductBySku } from "../reducers/products/listingSelectors";
import { isEmpty } from "lodash";
import { useModal } from "react-modal-hook";
import CouponCodeModal from "./CouponCodeModal";
import { useTranslation } from "react-i18next";
import ChatBox from "./ChatBox";
import { formatDistance } from "date-fns";
import MediaQuery from "react-responsive";
import StarIcon from "../icons/StarIcon";
import AlarmIcon from "../icons/AlarmIcon";
import { isNil } from 'lodash';
import { mapLocaleToDateLocale } from "../utils/locales";

export const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { sku } = useParams();
  const locale = i18n.language;

  const [showCouponCodeModal, hideCouponCodeModal] = useModal(() => {
    return <CouponCodeModal onClose={hideCouponCodeModal} sku={sku} />;
  });

  useEffect(() => {
    dispatch(getProductBySku({ sku, navigate, locale }));
  }, [dispatch, sku, navigate, locale]);

  const product = useSelector(selectProductBySku);

  if (isEmpty(product)) {
    return null;
  }

  const {
    discount,
    description,
    topReview,
    name,
    price,
    url,
    listPrice,
    imageUrl,
    imageAlt,
    rating,
    ratingCount,
    endDate,
  } = product;

  return (
    <MediaQuery minWidth={800}>
      {(matches) =>
        matches ? (
          <div className="product-page-detail-container">
            <div className="product-page-container">
              <div className="product-detail-title">{name}</div>
              <div className="price-container">
                <div className="price">${price.toFixed(2)}</div>
                <div className="list-price">${listPrice.toFixed(2)}</div>
                <div className="rating-title">
                  {t("product_detail_rating")}:
                </div>
                <div className="rating">{rating} / 5.0</div>
                <div className="rating-count">
                  <span>{ratingCount}</span>
                  {t("product_detail_rating")}
                </div>
                {product.endDate && product.endDate !== "" && (
                  <div className="product-code-expire-time">
                    <AlarmIcon height="20px" width="20px" />
                    <span>
                      {t("product_detail_expired_time", {
                        expiredTime: formatDistance(
                          new Date(product.endDate),
                          new Date(),
                          {
                            locale: mapLocaleToDateLocale({
                              i18nLocale: locale,
                            }),
                          },
                        ),
                      })}
                    </span>
                  </div>
                )}
              </div>
              <div className="coupon-code-container">
                <div className="product-discount">{discount}% OFF</div>
                <div
                  className="product-coupon-code-btn"
                  onClick={showCouponCodeModal}
                >
                  <div className="product-coupon-icon">
                    <CouponIcon width="30px" height="30px" />
                  </div>
                  <span>{t("product_detail_get_code")}</span>
                </div>
              </div>
              <div className="product-page-details">
                <div className="product-image">
                  <img src={imageUrl} alt={imageAlt} />
                </div>
                <div className="product-description">
                  <div className="product-description-title">
                    {t("product_detail_description")}
                  </div>
                  <ul>
                    {description &&
                      description
                        .split("\n")
                        .map((content, idx) => <li key={idx}>{content}</li>)}
                  </ul>
                  <div
                    className="product-page-url"
                    onClick={() => window.open(`${url}`, "_blank")}
                  >
                    {t("product_detail_purchase_title")}
                  </div>
                </div>
              </div>

              {topReview && !isNil(JSON.parse(product?.topReview)?.topReview?.body) ? (
                <div className="product-top-review-and-other">
                  <div className="product-other" />
                  <div className="product-top-review">
                    <div className="product-top-review-title">
                      {t("product_detail_top_review_title")}
                    </div>
                    <ul>
                      <li key="product-top-review">
                        {JSON.parse(product?.topReview)?.topReview?.body}
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
            <ChatBox />
          </div>
        ) : (
          <div className="mobile-product-page-detail-container">
            <div className="mobile-product-detail-title">{name}</div>
            <div className="mobile-price-container">
              <div className="mobile-price">${price.toFixed(2)}</div>
              <div className="mobile-list-price">${listPrice.toFixed(2)}</div>
              <div className="mobile-product-discount">{discount}% OFF</div>
              <div className="mobile-rating">
                <StarIcon />
                {rating} / 5.0
              </div>
              <div className="mobile-rating-count">
                <span className="mobile-rating-text">
                  ({ratingCount} {t("product_detail_rating")})
                </span>
              </div>
            </div>
            {product.endDate && product.endDate !== "" && (
              <div className="mobile-product-code-expire-time-container">
                <div className="mobile-product-code-expire-time">
                  <AlarmIcon height="20px" width="20px" />
                  <span>
                    {t("product_detail_expired_time", {
                      expiredTime: formatDistance(
                        new Date(endDate),
                        new Date(),
                        {
                          locale: mapLocaleToDateLocale({ i18nLocale: locale }),
                        },
                      ),
                    })}
                  </span>
                </div>
              </div>
            )}
            <div className="mobile-product-image">
              <img src={imageUrl} alt={imageAlt} />
            </div>
            <div className="mobile-product-description">
              <div className="mobile-product-description-title">
                {t("product_detail_description")}
              </div>
              <ul className="mobile-description-list">
                {description &&
                  description
                    .split("\n")
                    .map((content, idx) => <li key={idx}>{content}</li>)}
              </ul>
              {topReview && !isNil(JSON.parse(product?.topReview)?.topReview?.body) ? (
                <div className="mobile-product-top-review">
                  <div className="mobile-product-top-review-title">
                    {t("product_detail_top_review_title")}
                  </div>
                  <ul>
                    <li key="mobile-product-top-review">
                      {JSON.parse(product.topReview)?.topReview?.body}
                    </li>
                  </ul>
                </div>
              ) : null}
              <div
                className="mobile-product-page-url"
                onClick={() => window.open(`${url}`, "_blank")}
              >
                {t("product_detail_purchase_title")}
              </div>
            </div>
            <div className="mobile-coupon-code-container">
              <div
                className="mobile-product-coupon-code-btn"
                onClick={showCouponCodeModal}
              >
                <span>{t("product_detail_get_code")}</span>
              </div>
            </div>
          </div>
        )
      }
    </MediaQuery>
  );
};
