import axios from "axios";

const host =
  process.env.REACT_APP_STAGE === "dev"
    ? "http://192.168.1.110:7080"
    : "https://app.kupon.ai";

const OPEN_AI_API_KEY = "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";

axios.defaults.baseURL = `${host}/api/v1`;
const mainInstance = axios.create();

const getContentType = (httpMethod) =>
  httpMethod === "patch"
    ? "application/json-patch+json;charset=UTF-8"
    : "application/json;charset=UTF-8";

mainInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": getContentType(config.method),
      "Access-Control-Allow-Origin": "*",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const checkEmailExists = (email) => {
  return mainInstance.post("/users/checkEmail", { email });
};

export const loginUser = ({ data }) => {
  return mainInstance.post("/users/login", data);
};

export const addUser = ({ data }) => {
  return mainInstance.post("/users/add", data);
};

export const searchProducts = ({ query = "*", pageNum = 0, pageSize = 20 }) => {
  return mainInstance.get(
    `/products/search?query=${query}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
};

export const getProductBySku = ({ sku, locale = "en-US" }) => {
  return mainInstance.get(`/products/product/${sku}?locale=${locale}`);
};

export const getProductByCategoryWithPageSize = ({
  category = "",
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products?categories=${category}&pageNum=${pageNum}&pageSize=${pageSize}&locale=${locale}`,
  );
};

export const postSendMessageRequest = ({ data }) => {
  return mainInstance.post("/email", data);
};

export const postMessagesToChatGPT = ({ messages }) => {
  return axios.post(
    "https://api.openai.com/v1/chat/completions",
    { model: "gpt-3.5-turbo", messages },
    {
      headers: {
        Authorization: `Bearer ${OPEN_AI_API_KEY}`,
        ContentType: "application/json",
      },
    },
  );
};
