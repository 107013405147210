import { AMAZON } from "../../utils/constants";
import { formatInTimeZone } from "date-fns-tz";
import * as types from "./actionTypes";

const initialState = () => {
  return Object.assign(
    {},
    {
      category: {
        id: 2,
        label: "Beauty",
      },
      load: {
        hasMore: true,
        pageNum: 0,
      },
      search: {
        hasMore: true,
        pageNum: 0,
      },
      query: "",
      platform: AMAZON,
      product: {},
      productsByCategory: [],
      searchProducts: [],
      cutOffDate: getYesterdayMidnightTime(),
    },
  );
};

const getYesterdayMidnightTime = () => {
  const dateTime = new Date();
  dateTime.setDate(dateTime.getDate() - 7);
  dateTime.setHours(14, 0, 0, 0);
  return formatInTimeZone(dateTime, "UTC", "yyyy-MM-dd'T'HH:mm:ss");
};

const handleSetActiveCategoryId = (state, action) => {
  const { category } = action.payload;
  return {
    ...state,
    category,
    productsByCategory: [],
    load: {
      hasMore: true,
      pageNum: 0,
    },
  };
};

const handleResetProductsByCategory = (state) => {
  return {
    ...state,
    productsByCategory: [],
    load: {
      hasMore: true,
      pageNum: 0,
    },
  };
};

const handleSetSearchQuery = (state, action) => {
  const { query } = action.payload;
  if (query.length === 0) {
    return {
      ...state,
      query,
      searchProducts: [],
    };
  }
  return {
    ...state,
    query,
  };
};

const handleSearchProducts = (state, action) => {
  const {
    searchProducts,
    search: { pageNum, query: prevQuery },
  } = state;
  const { products = [], query } = action.payload;
  if (products.length === 0) {
    return { ...state, search: { hasMore: false, pageNum } };
  }
  if (prevQuery !== query) {
    return {
      ...state,
      searchProducts: [...products],
      search: {
        hasMore: true,
        pageNum: pageNum + 1,
        query
      },
    };
  }
  return {
    ...state,
    searchProducts: [...searchProducts, ...products],
    search: {
      hasMore: true,
      pageNum: pageNum + 1,
      query
    },
  };
};

const handleGetProductBySku = (state, action) => {
  const { product } = action.payload;
  return { ...state, product };
};

const handleGetProductsByCategory = (state, action) => {
  const {
    productsByCategory,
    load: { pageNum },
  } = state;
  const { products } = action.payload;
  if (products.length === 0) {
    return { ...state, load: { hasMore: false, pageNum: pageNum } };
  }
  return {
    ...state,
    productsByCategory: [...productsByCategory, ...products],
    load: {
      hasMore: true,
      pageNum: pageNum + 1,
    },
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.PRODUCTS_SET_ACTIVE_PRODUCT_CATEGORY_ID:
      return handleSetActiveCategoryId(state, action);
    case types.PRODUCTS_SET_SEARCH_QUERY:
      return handleSetSearchQuery(state, action);
    case types.PRODUCTS_SEARCH_PRODUCTS:
      return handleSearchProducts(state, action);
    case types.PRODUCTS_GET_PRODUCT_BY_SKU:
      return handleGetProductBySku(state, action);
    case types.PRODUCTS_GET_PRODUCTS_BY_CATEGORY:
      return handleGetProductsByCategory(state, action);
    case types.PRODUCTS_RESET_PRODUCTS_BY_CATEGORY:
      return handleResetProductsByCategory(state);
    default:
      return state;
  }
};

export default reducer;
