import React from "react";
import "./products.scss";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import StarIcon from "../icons/StarIcon";

export const Products = ({ products = [] }) => {
  return (
    <div className="products-container">
      {products.map(
        (
          {
            sku,
            imageUrl,
            imageAlt,
            name,
            listPrice,
            price,
            rating,
            ratingCount,
          },
          idx,
        ) => {
          const discount = (((listPrice - price) / listPrice) * 100).toFixed(0);
          return (
            <div key={idx} className="product-item">
              <div className="product-details">
                <Link to={`/product/${sku}`}>
                  <AsyncImage
                    src={imageUrl}
                    style={{ width: "100%", height: "140px", borderRadius: 3 }}
                    Transition={Blur}
                    loader={<div style={{ background: "#888" }} />}
                  />
                </Link>
                <p className="product-title">{name}</p>
                <div className="product-price">
                  <span className="product-detail-price">${price}</span>
                  <span className="product-origin-price">${listPrice}</span>
                  <span className="discount-percentage">
                    {`${discount}%`} OFF
                  </span>
                </div>
                <div className="product-comment">
                  <StarIcon className="product-rating-star" />
                  <span>{rating.toFixed(1)}</span>&nbsp;
                  <span className="product-rating-count">({ratingCount})</span>
                </div>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
