import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./searchBar.scss";
import InputWithIcon from "./InputWithIcon";
import SearchIcon from "../icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  searchProductsByQuery,
  setSearchQuery,
} from "../reducers/products/actions";
import {
  selectSearchProductParams,
  selectSearchQuery,
} from "../reducers/products/listingSelectors";
import { useTranslation } from "react-i18next";

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const query = useSelector(selectSearchQuery);
  const { pageNum } = useSelector(selectSearchProductParams);
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get("q");

  useEffect(() => {
    if (queryParams !== null) {
      dispatch(setSearchQuery(queryParams));
      dispatch(searchProductsByQuery({ query: queryParams, navigate, pageNum: 0 }));
    }
  }, [dispatch, queryParams, navigate]);

  const handleSetQuery = (event) => {
    const { value: query } = event.target;
    dispatch(setSearchQuery(query));
  };

  const handleOnKeyPress = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    handleSearchProducts(event);
  };

  const handleSearchProducts = (event) => {
    event.stopPropagation();
    if (query.length === 0) {
      return;
    }
    dispatch(searchProductsByQuery({ query, navigate, pageNum }));
  };

  return (
    <div className="menu-search-bar">
      <InputWithIcon
        id="query"
        type="text"
        placeholder={t("menu_bar_search_default")}
        containerClassName="search-bar"
        icon={
          <SearchIcon className="search-icon" onClick={handleSearchProducts} />
        }
        value={query}
        onChange={handleSetQuery}
        onKeyPress={handleOnKeyPress}
      />
    </div>
  );
};

export default SearchBar;
