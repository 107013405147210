export const productCategories = [
  // { id: 1, label: "Clothing", code: "product_category_recommendation" },
  { id: 2, label: "Beauty", code: "product_category_beauty" },
  { id: 3, label: "Clothing", code: "product_category_fashion" },
  { id: 4, label: "Electronics", code: "product_category_electronics" },
  {
    id: 5,
    label: "Cell+Phones+%26+Accessories",
    code: "product_category_cellphone",
  },
  { id: 6, label: "Living+%26+Dining", code: "product_category_home_kitchen" },
  { id: 7, label: "Office", code: "product_category_office" },
  { id: 8, label: "Toys+%26+Games", code: "product_category_toys_games" },
  {
    id: 9,
    label: "Sports+%26+Outdoors",
    code: "product_category_sports_outdoor",
  },
  {
    id: 10,
    label: "Home+Improvement",
    code: "product_category_home_improvement",
  },
  {
    id: 11,
    label: "Pet+Supplies",
    code: "product_category_pet_supplies",
  },
  {
    id: 12,
    label: "Automotive",
    code: "product_category_automotive",
  },
];
